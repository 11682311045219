const colors = {
  white: '#FFFFFF',
  neutralGrey80: '#333333',
  neutralGrey60: '#666666',
  neutralGrey30: '#b3b3b3',
  neutralGray20: '#cccccc',
  neutralGrey10: '#e6e6e6',
  neutralGrey5: '#F2F2F2',
  brandPrimary: '#0466E9',
  brandPrimary10p: 'rgba(4, 102, 233, 0.1)',
  greenSucessGreen50: '#10923C',
  redErrorRed50: '#DA2D2A',
};

export type Colors = keyof typeof colors;

export default colors;
