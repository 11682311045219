import ApiError from 'types/ApiError';
import { User, UserApiResponse } from 'types/User';
import requestPortal from './portal';
import { METHOD_GET_USERS } from './constants';
import { isApiError } from './utils/isApiError';
import parseUsers from './helpers/parseUserHelper';

const getUsers = async (companyId: number, userId: number): Promise<Array<User> | ApiError> => {
  const params = {
    companyId,
    userId,
    activeFL: true,
    hasSelectedCompany: true,
    todos: true,
  };
  const response = await requestPortal<UserApiResponse>(METHOD_GET_USERS, params);
  if (isApiError(response)) {
    return response as ApiError;
  }

  const userResponse = response as UserApiResponse[];
  const departments: User[] = userResponse.map((apiResponseItem) => parseUsers(apiResponseItem));

  return departments;
};

export default getUsers;
