import { Dispatch, SetStateAction, useEffect } from 'react';
import { User } from 'types/User';
import { FilterUserType } from '../types';

const useFilterUsers = (
  users: User[],
  filterParams: FilterUserType,
  setUsersFiltered: Dispatch<SetStateAction<User[]>>,
) => {
  const filterUsers = (_data: User[], _filterParams: FilterUserType) => {
    if (!_filterParams) return _data;

    const filterByTextFieldCondition = (item: User): boolean => {
      return 'name' in _filterParams && 'email' in _filterParams
        ? item.name.includes(_filterParams.name!) || item.email.includes(_filterParams.email!)
        : true;
    };

    return _data.filter((item) => item.flActive === _filterParams.active && filterByTextFieldCondition(item)) || _data;
  };

  useEffect(() => {
    if (users.length > 0) {
      setUsersFiltered(filterUsers(users, filterParams));
    }
  }, [users, filterParams, setUsersFiltered]);
};

export default useFilterUsers;
