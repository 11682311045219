import Loading from 'components/UiComponents/Loading/Loading';
import AlertContext from 'contexts/AlertContext/AlertContext';
import usePaginationPortalResponse from 'hooks/usePaginationPortalResponse';
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getUsers from 'services/users';
import { hasApiError } from 'services/utils/isApiError';
import { User } from 'types/User';
import useFilterUsers from './hooks/useFilterUsers';
import useTabChange from './hooks/useTabChange';
import { FilterUserType } from './types';

type UsersContextDataType = {
  users: User[];
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  setCurrentPage: (page: number) => void;
  pageData: User[];
  pagesCount: number;
  setFilterParams: Dispatch<SetStateAction<FilterUserType>>;
};

const UsersContext = createContext({} as UsersContextDataType);

const UsersContextProvider = ({ children }: PropsWithChildren) => {
  const alert = useContext(AlertContext);
  const { companyId, userId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
  const [filterParams, setFilterParams] = useState<FilterUserType>({ active: true });
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersContextError, setUsersContextError] = useState(false);
  const { pageData, pagesCount } = usePaginationPortalResponse(usersFiltered, currentPage);
  useFilterUsers(users, filterParams, setUsersFiltered);
  useTabChange(selectedTab, setFilterParams, setCurrentPage);

  const getUsersRequest = async (_companyId: number, _userId: number) => {
    const responseUsers = await getUsers(_companyId, _userId);
    if (hasApiError([responseUsers])) {
      setUsersContextError(true);
      return;
    }
    setUsers(responseUsers as User[]);
  };

  useEffect(() => {
    const validCompanyIdNumber = Number(companyId);
    const validUserId = Number(userId);
    if (!isNaN(validCompanyIdNumber) && !isNaN(validUserId)) {
      getUsersRequest(validCompanyIdNumber, validUserId);
    } else {
      setUsersContextError(true);
    }
  }, [companyId, userId]);

  useEffect(() => {
    if (usersContextError) {
      alert.defaultAlerts.defaultError();
    }
  }, [usersContextError, alert]);

  useEffect(() => {
    if (pageData.length > 0 || usersContextError) {
      setLoading(false);
    }
  }, [usersContextError, pageData]);

  return (
    <UsersContext.Provider
      value={{ users, selectedTab, setSelectedTab, setCurrentPage, pageData, pagesCount, setFilterParams }}
    >
      {isLoading ? <Loading fullscreen /> : children}
    </UsersContext.Provider>
  );
};

function useUsersContext() {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error('UsersContext can`t be initialized');
  }
  return context;
}

export { UsersContextProvider, useUsersContext, UsersContextDataType };
export default UsersContext;
