import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const FONT_FAMILY = 'Inter';

const Body = styled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

const BodyBold = styled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

const BodySmall = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`;

const BodySmallBold = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`;

const BodyMini = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
`;

const BodyMiniBold = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const BodyMicro = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
`;

const H4 = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
`;

const H5 = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
`;

const H6 = styled(Typography)`
  font-family: ${FONT_FAMILY};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
`;

export { Body, BodySmall, BodySmallBold, BodyMini, BodyMiniBold, BodyMicro, BodyBold, H4, H5, H6 };
