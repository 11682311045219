import { useEffect, useMemo, useState } from 'react';

const usePaginationPortalResponse = <T,>(data: Array<T>, currentPage: number, numberOfItems = 16) => {
  const [pageData, setPageData] = useState<Array<T>>([]);
  const [pagesCount, setPageCount] = useState(0);
  const currentPageIndex = useMemo(() => currentPage - 1, [currentPage]);

  useEffect(() => {
    const startOffset = currentPageIndex > 0 ? currentPageIndex * numberOfItems - 1 : 0;

    const calcFinalOffset = startOffset + numberOfItems;
    const finalOffset = calcFinalOffset > data.length ? data.length : calcFinalOffset;

    const incompletePages = data.length / numberOfItems > 0 ? 1 : 0;
    const _pagesCount = Math.trunc(data.length / numberOfItems) + incompletePages;
    const _pageData = data.slice(startOffset, finalOffset);

    setPageCount(_pagesCount);
    setPageData(_pageData);
  }, [data, currentPageIndex, numberOfItems]);

  return { pageData, pagesCount };
};

export default usePaginationPortalResponse;
