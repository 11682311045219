import { User, UserApiResponse } from 'types/User';

const parseUsers = (apiResponseItem: UserApiResponse): User => {
  return {
    departmentDescription: apiResponseItem.descdepartamento,
    flCard: apiResponseItem.flcartao,
    phone: apiResponseItem?.telefone,
    roleDescription: apiResponseItem.desccargo,
    costCenterId: apiResponseItem.idcentrodecusto,
    name: apiResponseItem.nome,
    birthData: apiResponseItem.datanascimento,
    userId: apiResponseItem.idusuario,
    userCode: apiResponseItem?.codigousuario,
    companyId: apiResponseItem.idempresa,
    gender: apiResponseItem?.genero,
    cpf: apiResponseItem.cpf,
    lastName: apiResponseItem.sobrenome,
    flActive: apiResponseItem.flativo,
    userName: apiResponseItem.nomeusuario,
    email: apiResponseItem.email,
    profileDescription: apiResponseItem.descperfil,
  };
};

export default parseUsers;
