import { Dispatch, SetStateAction, useEffect } from 'react';
import { FilterUserType } from '../types';
import { TAB_ACTIVE } from '../constants';

const useTabChange = (
  selectedTab: number,
  setFilterParams: Dispatch<SetStateAction<FilterUserType>>,
  setCurrentPage: Dispatch<SetStateAction<number>>,
) => {
  useEffect(() => {
    if (selectedTab === TAB_ACTIVE) {
      setFilterParams({ active: true });
    } else {
      setFilterParams({ active: false });
    }
    setCurrentPage(1);
  }, [selectedTab, setCurrentPage, setFilterParams]);
};

export default useTabChange;
