import Loading from 'components/UiComponents/Loading';
import { UsersContextProvider } from 'contexts/UsersContext/UsersContext';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const UserList = lazy(() => import('components/UserList'));
const AppRoutes = () => {
  return (
    <Suspense fallback={<Loading fullscreen />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="user-list/:companyId/:companyName/:userId"
            element={
              <UsersContextProvider>
                <UserList />
              </UsersContextProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
